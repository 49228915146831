(function() {
    'use strict';
    angular
        .module('seatonApp')
        .factory('AppService', AppService);

    AppService.$inject = ['$resource'];

    function AppService($resource) {
        var resourceUrl = 'api/auth/admin/locations';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'applications': { method:'GET', url: 'api/admin/applications/:cloverId', isArray: true },
            'clearServerCache': { method:'GET', url: 'api/admin/clearServerCache', isArray: false },
            'threadPoolsStatus': { method:'GET', url: 'api/admin/threadPoolsStatus', isArray: false },
            'searchByEmployeeOnServer': { method:'GET', url: 'api/admin/searchByEmployee/:searchParam', isArray: true},
            'resetCounter': { method:'POST', url: 'api/admin/resetCounter'},
            'changeSubscription': { method:'POST', url: 'api/admin/changeSubscription', isArray: true},
            'refreshToken': { method:'GET', url: 'api/admin/refreshToken/:locationId'},
            'migrateToken': { method:'GET', url: 'api/admin/migrateToken/:locationId'},
            'refreshOnLogin': { method:'GET', url: 'api/admin/refreshOnLogin/:locationId'},
            'accessTokenLocationList': { method:'GET', url: 'api/admin/accessTokenLocationList', isArray: true},
        });
    }
})();
