(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('AccessTokenController', AccessTokenController);

    AccessTokenController.$inject = ['$rootScope', '$state', 'AppService', 'Notification', 'ParseLinks', 'Log'];

    function AccessTokenController($rootScope, $state, AppService, Notification, ParseLinks, Log) {
        var vm = this;

        vm.getAllLocations = getAllLocations;
        vm.refreshToken = refreshToken;
        vm.migrateToken = migrateToken;
        vm.getDateFromUnixEpoch = getDateFromUnixEpoch;
        vm.refreshOnLogin = refreshOnLogin;

        getAllLocations();

        function getAllLocations() {
            AppService.accessTokenLocationList({}, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.locations = data;
            }

            function onError(error) {
                Notification.error(error.data.message);
            }
        }

        function refreshToken(locationId) {
            var params = {
                'locationId': locationId
            };

            AppService.refreshToken(params, onSuccess, onError);

            function onSuccess(data, headers) {
                if(data.id) {
                    Notification.info("Token refresh successfully completed!");
                } else {
                    Notification.error("Token refresh failed. Look into server log file for more details.");
                }

                getAllLocations();
            }

            function onError(error) {
                Notification.error("Token refresh failed. Look into server log file for more details.");
            }
        }

        function migrateToken(locationId) {
            var params = {
                'locationId': locationId
            };
            AppService.migrateToken(params, onSuccess, onError);

            function onSuccess(data, headers) {
                if(data.id) {
                    Notification.info("Migration successfully completed. ");
                } else {
                    Notification.error("Migration failed. Look into server log file for more details.");
                }
                getAllLocations();
            }

            function onError(error) {
                Notification.error("Token refresh failed. Look into server log file for more details.");
            }
        }

        function refreshOnLogin(locationId) {
            var params = {
                'locationId': locationId
            };
            AppService.refreshOnLogin(params, onSuccess, onError);

            function onSuccess(data, headers) {
                if(data.id) {
                    Notification.info("Refresh on login requested successfully. ");
                } else {
                    Notification.error("Token refresh request on login failed. Look into server log file for more details.");
                }
                getAllLocations();
            }

            function onError(error) {
                Notification.error("Token refresh request on login failed. Look into server log file for more details.");
            }

        }

        function getDateFromUnixEpoch(epoch) {
            if(epoch) return new Date(epoch * 1000);
            else return "";
        }

    }
})();
